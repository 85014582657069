* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body { 
    font-family: sans-serif;
    overflow: hidden;
}

h1 {
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 58px;
    line-height: 1.2em;
}

p {
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.3em;
}

.link_styles {
    text-decoration: none;
    color: rgb(175, 175, 175);
    transition: 0.2s ease-in-out;
}

.link_styles:hover {
    color: #fff;
}

:root {
    --primary_color: #17252a;
    --secondary_color: #3aafa9;
    --lightgrey: #F4F4F4;
    --darkgrey: #131313;
    --lightblue: #99CCFF;
    --darkerblue: #2b7A78

}

.soft_shadow {
    -webkit-box-shadow: 0px 7px 45px -9px rgba(201, 201, 201, 0.29);
    -moz-box-shadow: 0px 7px 45px -9px rgba(189, 189, 189, 0.29);
    box-shadow: 0px 7px 45px -9px rgba(197, 197, 197, 0.29);
}

.text_secondary {
    color: #A6A6A6;
}

.splash_styles {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary_color);
    position: fixed;
    z-index: 6;
}

.section_container {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform, opacity;
    overflow-y: scroll;
    transform: translateY(-100vh);
    transition: 0.5s ease-in-out;
}

.current_page {
    /* z-index: 1; */
    transform: translateY(0);
}

.next_page {
    /* z-index: 0; */
    transform: translateY(0);
}

.splash_elements_container {
    width: 400px;
}

.cog_loading_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.splash_cog {
    height: 85px;
    position: relative;
    margin-bottom: 15px;
}

.splash_cog:nth-of-type(1) {
    bottom: 10px;
}

.splash_cog:nth-of-type(2) {
    height: 60px;
    right: 13px;
}

.progress_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress_label {
    color: #fff;
    margin-bottom: 5px;
}

.progress_bar {
    width: 100%;
    border: 1px solid #fff;
}

.splash_texts_container {
    color: var(--primary_color);
}

.splash_header {
    margin: 30px 0 20px 0;
}

.hover_nav_container { 
    position: absolute;
    z-index: 5;
    left: 40px;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle-icon {
    margin: 7px 0px;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    border: 3px solid #a1a1a1;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.circle-icon:hover {
    border-color: #fff;
}


.circle-filled {
    background-color: #fff;
    border-color: #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    color: #fff;
    padding: 0 4%;
}

.nav_button {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
}

.nav_button:hover {
    transform: scale(1.1);
}

.nav_menu_close {
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.nav_menu_close:hover {
    transform: scale(1.1);
}

.nav_menu {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    position: fixed;
    z-index: 6;
    display: flex;
    position: relative;
    transition: 0.4s ease-in-out;
    position: fixed;
    opacity: 1;
}

.nav_menu_hide {
    z-index: 0;
    opacity: 0;
    transform: translateX(100vw);
}

.nav_illustration {
    width: 21%;
    height: 100%;
    background-color: var(--primary_color);
}

.nav_content {
    width: 79%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.nav_inner {
    width: 900px;
    display: flex;
    height: 500px;
}

.nav_info {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.nav_menu_socials {
    display: flex;
}

.nav_menu_socials > div:first-of-type {
    margin-left: 0;
}

.nav_menu_socials > div {
    display: flex;
    margin: 0 20px;
}

.menu_socials_text {
    color: grey;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.menu_socials_text:hover {
    color: black;
}

.menu_socials_text,
.nav_personal_info > div > p {
    font-size: 21px;
    margin-left: 30px;
}

.nav_personal_info > div:first-of-type {
    margin-top: 0;
}

.nav_personal_info > div {
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.nav_inner > ul {
    width: 50%;
    list-style: none;
}

.nav_inner > ul > li:first-of-type {
    margin-top: 0;
}

.nav_inner > ul > li {
    margin: 20px 0;
    overflow: hidden;
}

.nav_menu_options {
    transition: transform 0.7s ease-in-out, color 0.2s ease-in-out;
    transform: translateY(0);
    display: block;
    text-decoration: none;
    color: #000;
    width: 200px;
    cursor: pointer;
}

.hide_text {
    transform: translateY(100px);
}

.nav_menu_options:hover {
    color: var(--secondary_color);
}

.nav_li_container {
    display: flex;
    list-style: none;
}

.nav_li_container > li {
    margin-right: 60px;
}

.anchorLink_style {
    cursor: pointer;
}

.tech_ul_style {
    display: flex;
    list-style: inside;
    font-size: 14px;
}

.tech_ul_style > div {
    margin-right: 80px;
}

.tech_ul_style > div > li {
    margin-bottom: 10px;
}

.about_cta_container {
    display: flex;
    /* width: 500px; */
}

.about_cta {
    border: 1px solid #fff;
    height: 55px;
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    transform: scale(1);
    color: #fff;
}

.about_cta:hover {
    background-color: var(--secondary_color);
    color: var(--primary_color);
    border-color: var(--secondary_color);
    transform: scale(1.1);
}

.home_section {
    width: 100%;
    background-color: var(--primary_color);
    background-image: url(../assets/images/bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    /* Fixes next tab animation */
    overflow: hidden;
}

.home_nextTab_container {
    transition: 0.4s ease-in-out;
    transform: translateY(100px);
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    margin-bottom: 100px;
}

.header_elems_container {
    width: 1000px;
    display: flex;
    justify-content: center;
    text-align: center;

}

.header_texts_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_sub_text_container {
    display: flex;
    align-items: center;
}

.header_sub_text {
    color: var(--secondary_color);
    font-size: 16px;
    margin: 0 15px;
}

.header_main_text {
    color: #fff;
    margin: 30px 0 20px 0;
}

.header_description {
    width: 450px;
    margin-bottom: 40px;
}

.header_buttons_container {
    display: flex;
    justify-content: space-between;
    width: 400px;
}

.small_header {
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.small_header_text {
    margin: 0px 15px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.thin_line {
    border: 1px solid #fff;
    width: 45px;
}

.home_about {
    display: flex;
    min-height: 500px;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding-bottom: 300px;
}

.profile_photo {
    width: 100%;
    margin-bottom: 35px;
}

.center_text {
    width: 500px;
}

.center_text > img,
.center_text > p {
    margin-bottom: 35px;
}

.next_tab {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.blog_section {
    height: 100%;
    overflow: hidden;
}

.blog_next_container {
    transform: translateY(-50px);
    transition: 0.3s ease-in-out;
}

.blog_next_container_hidden {
    transform: translateY(0px);
}

.blog_header {
    font-weight: 500;
    margin-bottom: 10px;
}

.blog_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    position: relative;
}

.black_overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.4;
}

.blog_content {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: 0.3s ease-in-out;
    translate: transformY(0px);
    opacity: 1;
}

.from_bottom {
    transform: translateY(50px);
    opacity: 0;
}

.blog_texts_container {
    text-align: center;
    width: 550px;
    color: #fff;
}

.blog_texts_contianer > h1 {
    margin-bottom: 15px;
}

.cta_1 {
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 190px;
    border-radius: 27px;
    margin-top: 35px;
    color: #fff;
    cursor: pointer;
}

.projects_section {
    min-height: 100vh;
    background-color: var(--secondary_color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: var(--primary_color);
}

/* New Project Styles */

.work_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 70px;
}

.experience_container {
    display: flex;
    height: 350px;
}

.experience_header {
    font-size: 19px;
}

.experience_selection {
    list-style: none;
}

.experience_menu {
    border-left: 4px solid var(--darkerblue);
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.experience_selected {
    border-color: #fff;
    color: #fff;
}

.experience_contents {
    width: 500px;
}

#experience_contents_header,
#experience_contents_description {
    transition: 0.3s ease-in-out;
}

#experience_contents_header > h2 {
    margin-bottom: 8px;
}

.experience_content_header_sentence > a {
    text-decoration: none;
    color: #fff;
}

.experience_contents_info {
    margin-top: 30px;
}

#experience_contents_description > li:first-of-type {
    margin-top: 0;
}

#experience_contents_description > li:last-of-type {
    margin-bottom: 0;
}

.company_link {
    color: #fff;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    border-bottom: 3px solid transparent;
}

.company_link:hover {
    border-color: #fff;
}

#experience_contents_description > li {
    margin: 20px 0;
}

#experience_contents_description {
    list-style-position: outside;
}

.project_wrapper {
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project_ul_container {
    width: 90%;
}

.project_li {
    display: flex;
    margin-bottom: 150px;
}

.project_li_reverse {
    flex-direction: row-reverse;
}

.project_preview {
    width: 700px;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.project_preview:hover {
    -webkit-box-shadow: 0px 10px 31px -15px #000000; 
    box-shadow: 0px 10px 31px -15px #000000;
}

.project_info {
    /* padding-left: 70px; */
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project_info > div {
    margin: 10px 0;
}

.newProject_header {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 5px;
    
}

.newProject_description {
    padding: 15px;
    width: 400px;
    /* Styles */
    background-color: var(--primary_color);
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 31px -15px #000000; 
    box-shadow: 0px 10px 31px -15px #000000;
}

.newProject_subHeader {
    font-size: 26px;
    color: var(--primary_color);
}

.newProject_tech {
    display: flex;
    flex-wrap: wrap;
}

.newProject_tech > p {
    margin-right: 15px;
}

.newProject_link {
    margin-right: 15px;
    cursor: pointer;
}

/* End */

.project_header {
    margin-top: 50px;
    width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.project_header > h1 { 
    width: 400px;
    text-align: center;
}

.projects_ul {
    display: flex;
    list-style: none;
    width: 500px;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 80px 0;
}

.projects_category {
    color: grey;
    transition: 0.2s ease-in-out;
    padding: 10px 0;
    text-align: center;
    width: 80px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
}

.projects_category:hover{
    color: var(--darkgrey);
}

.projects_selected {
    color: var(--darkgrey);
    border-bottom: 3px solid var(--darkgrey);
}

.projects_showcase {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100vh; 
    margin-bottom: 50px;
}

.project_container {
    cursor: pointer;
    height: 600px;
    width: 530px;
    border-radius: 30px;
    overflow: hidden;
    background-color: #fff;
    margin: 30px;
    transition: 0.3s ease-in-out;
    /* transform: scale(0); */
    transform: translateY(70px);
    position: absolute;
    z-index: 0;
    opacity: 0;
    position: relative;
}

.project_container:hover {
    -webkit-box-shadow: 0px 0px 50px 11px #B4B4B4; 
    box-shadow: 0px 0px 50px 11px #B4B4B4;
}

.project_nextTab_container {
    transition: 0.3s ease-in-out;
    width: 100%;
    transform: translateY(50px);
}

.project_image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;
    background-position: top;
    background-size: cover;
    background-repeat: repeat;
}

.project_logo_container {
    width: 100%;
}

.img_inherit {
    width: 100%;
}

.project_description {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 25px;
    color: var(--darkgrey);
}

.project_tech_desc {
    font-size: 14px;
    color: grey;
    margin-top: 3px;
}

.contact_section {
    min-height: 100vh;
    background-color: var(--lightblue);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0;
    position: relative;
}

.contact_form_container {
    width: 1200px;
    height: 1400px;
    border-radius: 80px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
}

.contact_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--darkgrey);
    height: 100%;
}

.contact_header_desc {
    margin-top: 15px;
    width: 370px;
    text-align: center;
}

.contact_body,
.contact_socials {
    width: 100%;
    padding: 0 7%;
}

.contact_body {
    display: flex;
    margin: 5% 0;
}

.contact_info {
    width: 50%;
}

.contact_form {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.contact_form > input,
.contact_form > textarea,
.contact_cta {
    margin: 15px 0;
    border-radius: 10px;
    border: none;
    font-size: 16px;
}

.contact_form > input {
    outline: none;
    padding: 15px;
    height: 50px;
    border: none;
    background-color: #f4f4f4;
}

.contact_form > textarea {
    outline: none;
    border: none;
    background-color: #f4f4f4;
    height: 150px;
    resize: none;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    padding: 15px;
}

.contact_cta {
    height: 50px;
    width: 150px;
    border: none;
    font-family: 'Roboto', sans-serif;
    outline: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.contact_cta:hover {
    background-color: var(--secondary_color);
    color: #fff;
    transform: translateY(-5px);
}

.contact_cta:active {
    transform: translateY(0px);
    background-color: var(--primary_color);
}

.contact_socials {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
}

.contact_socials > a {
    border-radius: 15px;
    text-decoration: none;
    color: grey;
    transition: 0.2s ease-in-out;
}

.contact_socials > a:hover {
    color: var(--darkgrey);
}

.contact_transition {
    width: 100%;
    height: 1000px;
    position: absolute;
    bottom: 0;
    background-image: url(../assets/images/blueTransitional.svg);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

footer {
    background-color: var(--primary_color);
    /* padding: 0 4% 0 4%; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_contents {
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2F4E58;
    padding: 100px 0;
    width: 1200px;
}

.footer_header,
.footer_nav {
    width: 50%;
}

.footer_header > h1 {
    width: 700px;
}

.footer_header > p {
    margin-top: 50px;
    font-size: 28px;
}

#footer_header_text1 {
    transition: 0.3s ease-in-out;
    transform: translateX(-100px);
    opacity: 0;
}

#footer_header_text2 {
    transition: 0.3s ease-in-out;
    transform: translateX(-100px);
    opacity: 0;
}

.footer_nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer_nav > li > a {
    text-decoration: none;
    color: #fff;
}

.footer_nav > li:nth-of-type(1) {
    margin: 0 0 10px 0;
}

.footer_nav > li {
    margin: 10px 0;
    font-size: 18px;
    cursor: pointer;
}

.footer_disclaimer {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    color: #fff;
}

/** media queries **/

@media only screen and (max-width: 500px) { /** Mobile Version **/  
    header {
        margin-top: 50px;
    }
    .header_elems_container {
        justify-content: center;
    }
    .header_texts_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 80vw;
    }
    .header_sub_text {
        color: var(--secondary_color);
        font-size: 16px;
    }
    
    .header_main_text {
        color: #fff;
        margin: 30px 0 20px 0;
    }
    
    .header_description {
        width: 100%;
        margin-bottom: 40px;
    }
    
    .header_buttons_container {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 130px;
    }
    .splash_elements_container{
        padding: 0 15%;
    }
    .splash_texts_container {
        text-align: center;
    }
    h1 {
        font-size: 38px;
    }
    .hover_nav_container {
        left: 3%;
        display: none;
    }
    .nav_li_container {
        display: none;
    }
    .nav_home_container {
        display: flex;
        justify-content: flex-end;
        margin: 0 3%;
    }
    .nav_illustration {
        display: none;
    }
    .nav_content {
        width: 100%;
    }
    .nav_inner {
        flex-direction: column;
        width: 80%;
        margin-top: 100px;
    }
    .nav_info {
        display: none;
    }
    .img_header_container {
        height: 300px;
    }
    .center_text {
        width: 100%;
        padding: 0 10%;
    }
    .blog_texts_container {
        width: 80%;
    }
    .project_description {
        display: none;
    }
    .projects_ul {
        display: none;
    }
    .project_container {
        height: 72vh;
        /* width: 100vw; */
        margin: 0 0;
        border-radius: 0px;
    }
    .project_image_container {
        height: 100%;
    }
    .soft_shadow {
        box-shadow: none;
    }
    .project_header > h1 {
        width: 80vw;
        margin-bottom: 80px;
    }
    .projects_showcase {
        width: 100%;
        margin-bottom: 0;
    }
    .project_container:hover {
        box-shadow: none;
    }
    .contact_form_container {
        border-radius: 0;
    }
    .contact_section {
        padding: 0;
    }
    .contact_info {
        display: none;
    }
    .contact_header_desc {
        width: 80%;
    }
    .contact_body {
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    .contact_form {
        width: 80%;
    }
    .footer_contents {
        width: 100%;
    }
    .footer_header {
        width: 100%;
    }
    #footer_header_text1 {
        width: 100%;
        text-align: center;
    }
    #footer_header_text2 {
        text-align: center;
        font-size: 21px;
    }
    .footer_nav {
        display: none;
    }
    .nav_home_container {
        position: absolute;
        right: 0;
        top: 20px;
    }
    .experience_container {
        flex-direction: column;
        height: 500px;
    }
    .experience_selection {
        display: flex;
        justify-content: center;
        overflow-x: scroll;
    }
    .experience_menu {
        border-bottom: 4px solid var(--darkerblue);
        border-left: 0;
        width: 100px;
        margin-bottom: 50px;
    }
    .experience_selected {
        border-color: #fff;
    }
    .experience_contents{
        width: 100%;
        padding: 0 50px;
    }
    .project_preview {
        position: relative;
        width: 380px;
    }
    .project_info {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        height: 400px;
        overflow: hidden;
        width: 342px;
        align-items: center;
        border-radius: 5px;
    }
    .project_info > div {
        width: 80%;
    }
    .newProject_header {
        font-size: 27px;
    }
    .newProject_subHeader {
        font-size: 24px;
        color: var(--secondary_color);
    }
    .newProject_tech {
        flex-wrap: wrap;
        color: #fff;
    }
    .project_li {
        margin-bottom: 60px;
    }
    .newProject_link {
        color : #fff;
    }
}

@media only screen and (min-width: 501px) and (max-width: 960px) { /** tablet Version **/

}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    ::-webkit-scrollbar {
        width: 5px;
    }
      
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: grey;
    }
    ::-webkit-scrollbar-track {
        background-color: white;
    }
  }

/* @media only screen and (min-width: 961px) and (max-width: 1919px) {
    
}

@media only screen and (min-width: 1920px) {
    
} */